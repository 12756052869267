$light: white;
$dark: #424242;

@import "~bootstrap/scss/bootstrap";
@import "styles/icon";
@import "styles/button";
@import "styles/card";
@import "styles/a";
@import "styles/dialog";
@import "styles/menu";
@import "styles/themes";
@import "styles/font";
@import "styles/table";
@import "styles/_expansion-panel.scss";

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
  min-height: -webkit-fill-available;
}

body {
  height: 100%;
  min-height: -webkit-fill-available;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

::-moz-selection {
  background-color: #ff9900;
  color: $light;
}

::selection {
  background-color: #ff9900;
  color: $light;
}
