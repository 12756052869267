a {
  color: #ff9900;
}

.light-theme a:visited {
  color: #ff9900;
}

.dark-theme a:visited {
  color: #ff9900;
}

/* mouse over link */
a:hover {
  color: darken(#ff9900, 0.1);
}

/* selected link */
a:active {
  color: lighten(#ff9900, 0.1);
}
