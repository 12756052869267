.mat-menu-panel {
  .mat-menu-content {
    padding: 0.5em !important;
  }
}

.dark-theme {
  .mat-menu-content {
    color: white;
  }
}
