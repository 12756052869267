.mat-card {

  &.btc-no-margin {
    .mat-card-header-text {
      margin-left: 0;

      .mat-card-title {
        margin-bottom: 0;
      }
    }
  }

  border-radius: 0 !important;
  border: 2px solid gray;
}
